@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Noto+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Noto Serif', serif;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  height: 50vh;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.embla__slide img {
  width: 100%;
  margin: 0 auto;
  object-fit: fill;
}

.embla__slide img:hover {
  opacity: 0.6;
}